<template>
  <div class="flix-form-group" v-if="count">
    <div class="flix-container-fluid flix-bg-info">
      <div class="flix-text-info" style="padding: 10px 0"><flixIcon id="info-sign" /> <span v-html="$nl2br($t('message.deletionMsg').split('%count').join(count).split('%date').join(date))"></span><br><br><router-link :to="{name: 'dashboardDownloadCenter', params: {id: 'all'}}" class="flix-btn flix-btn-default">{{ $t('message.csvDownload') }}</router-link></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  mounted () {
    this.variables = this.$getUserVariables()
    this.getList()
  },
  computed: {

  },
  data () {
    return {
      variables: {},
      count: 0,
      date: this.getNextMonth()
    }
  },
  methods: {
    getNextMonth () {
      var d = new Date()
      d.setDate(1)
      d.setMonth(d.getMonth() + 1)

      // if (d.getMonth() === 9 && d.getFullYear() === 2020) {
      // d.setMonth(d.getMonth() + 1)
      // }

      var weekday = d.getDay()
      weekday = weekday - 1

      if (weekday < 0) {
        weekday = 6
      }
      return this.$t('message.weekdays')[weekday] + ', ' + this.$getNullsBefore(d.getDate()) + '.' + this.$getNullsBefore((d.getMonth() + 1)) + '.' + d.getFullYear()
    },
    getList () {
      this.$store.dispatch('getDeletionList',
        {
          user: this.variables.user.md5_id,
          callback: function (ret) {
            this.count = ret
          }.bind(this)
        })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
